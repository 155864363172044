var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    },
    attrs: {
      "color": _vm.sheetColor
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": _vm.justifySpaceBetween ? 'space-between' : undefined
    }
  }, [_c('v-col', _vm._b({
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, 'v-col', _vm.$attrs.headerProps, false), [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep"
  }, [_vm._v(_vm._s(_vm.term) + " "), _vm.required ? _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")]) : _vm._e()])]), _c('v-col', {
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "cols": "12",
      "md": _vm.justifySpaceBetween ? 'auto' : ''
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_vm._t("default")], 2)])], 1), !_vm.noDevider ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }